import React from "react"
import usePastEventsQuery from "../../../graphql/usePastEventsQuery."
import { StrapiEvent } from "../../../interfaces/Event"
import EventBlock from "../../Events/EventBlock"

const PastEvents = () => {
  const pastEvents = usePastEventsQuery()

  return (
    <div className="grid gap-5 md:grid-cols-3 md:gap-10">
      {pastEvents &&
        pastEvents.map((event: StrapiEvent) => {
          return <EventBlock key={event.slug} event={event} />
        })}
    </div>
  )
}

export default PastEvents
